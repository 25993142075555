

















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class CashAuditDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    amount: "",
    remark: "",
    id: 0
  };
  loading: boolean = false;
  rules: any = {
    amount: [
      { required: true, message: "请输入冻结/解冻金额", trigger: "blur" }
    ],
    remark: [{ required: true, message: "请输入备注信息", trigger: "blur" }]
  };
  show(id: string) {
    this.dialogFormVisible = true;
    this.formData = {
      amount: "",
      remark: "",
      id: id
    };
  }
  submitAudit() {
    (this.$refs["dialogForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Member/FreezeOrUnfreeze",
            this.formData
          );
          if (res.data.success) {
            this.dialogFormVisible = false;
            this.$notify.success("冻结/解冻成功");
            this.$emit("succeed");
          }
        } catch (error) {}
      }
      this.loading = false;
    });
  }
}
