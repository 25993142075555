





































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import MemberFreezeOrUnfreezeDialog from "./components/MemberFreezeOrUnfreezeDialog.vue";

@Component({
  components: {
    MemberFreezeOrUnfreezeDialog
  }
})
export default class Members extends Vue {
  pagerHeader: any = {
    title: "会员列表",
    desc: "会员列表",
    breadcrumb: ["会员列表", "会员管理"]
  };
  dialogFormVisible: boolean = false;
  dateRange: any[] = [];
  filter: any = {
    page: 1,
    size: 10,
    keywords: "",
    onlyShowAgent: false,
    clientType: null,
    sortDirection: null,
    sortField: null,
    startDate:null,
    endDate:null
  };
    //日期改变   
  timeVoi(value:Date[]){
    if (value != null && value.length == 2) {
        this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss")
        if(value[1].Format('hh')=="00"&&value[1].Format('mm')=="00"&&value[1].Format('ss')=="00"){
            this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59")]
        }else{
            this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")]
        }
      this.getData()
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.getData()
    } 
  }

  balanceDialog: any = {
    show: false,
    userName: "",
    form: {
      remark: "",
      amount: "",
      id: ""
    },
    rules: {
      name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      amount: [{ required: true, message: "请输入金额", trigger: "blur" }],
      remark: [{ required: true, message: "请输入备注", trigger: "blur" }]
    },
    loading: false
  };
  agentDialog: any = {
    show: false,
    userName: "",
    form: {
      parentId: null,
      level: 0,
      name: "",
      mobile: "",
      idCard: "",
      emailAddress: "",
      id: ""
    },
    rules: {
      name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      idCard: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
      emailAddress: [{ required: true, message: "请输入邮箱", trigger: "blur" }]
    },
    loading: false
  };
  loading: boolean = false;
  tableData: any = {
    items: [],
    totalCount: 0
  };

  // activated() {
  //   this.getData();
  // }
  created() {
    if(this.$route.query.time=="0"){
        this.getTime(0)
    }else if(this.$route.query.time=="1"){
        this.getTime(1)
    }else if(this.$route.query.time=="2"){
        this.getTime(7)
    }else if(this.$route.query.time=="3"){
        this.getTime(30)
    }else if(this.$route.query.id){
      this.filter.keywords=this.$route.query.id
    }
    this.filter.size = this.systemSettings.pageSize;
    this.getData();
  }
// 处理传过来的时间数字
    getTime(days:any){
        if(days==1){
           // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            // @ts-ignore
            this.filter.endDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")
           // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")]
        }else{
            // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            this.filter.endDate =new Date().Format("yyyy-MM-dd 23:59:59")
            // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date().Format("yyyy-MM-dd 23:59:59")]
        }
            
    }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  search() {
    this.filter.page = 1;
    this.getData();
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.post(
        "/api/services/app/Member/QueryPagedList",
        {
            page: this.filter.page,
            size: this.filter.size,
            keywords: this.filter.keywords,
            onlyShowAgent: this.filter.onlyShowAgent,
            clientType: this.filter.clientType===""?null:this.filter.clientType,
            sortDirection: this.filter.sortDirection,
            sortField: this.filter.sortField,
            startDate:this.filter.startDate,
            endDate:this.filter.endDate
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async resetPassword(user: any) {
    let res = await this.$confirm(
      "确定要将 " + user.name + " 的密码重置为123456？",
      "重置密码",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }
    );

    if (res == "confirm") {
      let res = await this.$ajax.post("/api/services/app/Admin/ResetPassword", {
        id: user.id
      });
      if (res.data.success) {
        this.$notify({
          title: "重置成功",
          message: "密码已重置为：123456",
          type: "success"
        });
      }
    }
  }
  openChangeBalance(index: number, row: any) {
    this.balanceDialog.show = true;
    this.balanceDialog.userName = row.name;
    this.balanceDialog.form.amount = "";
    this.balanceDialog.form.remark = "";
    this.balanceDialog.form.id = row.id;
  }
  addBalance() {
    (this.$refs.balanceForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.balanceDialog.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Member/AddAmount",
            this.balanceDialog.form
          );
          if (res.data.success) {
            var data = res.data.result;
            this.$notify.success(`增加成功`);
            this.getData();

            this.balanceDialog.show = false;
          }
        } catch (error) {}
        this.balanceDialog.loading = false;
      }
    });
  }
// 添加代理
  saveAgent() {
    (this.$refs.editAgentForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.agentDialog.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Agent/CreateOrUpdate",
            this.agentDialog.form
          );
          if (res.data.success) {
            var data = res.data.result;
            this.$notify.success(
              `${!this.agentDialog.addMode ? "修改" : "添加"}成功`
            );
            this.getData();

            this.agentDialog.show = false;
          }
        } catch (error) {}
        this.agentDialog.loading = false;
      }
    });
  }
  openAddAgentDialog(row: any) {
    this.agentDialog.show = true;
    this.agentDialog.userName = row.name;
    this.agentDialog.form = {
      parentId: null,
      level: 0,
      name: "",
      mobile: "",
      idCard: "",
      emailAddress: "",
      id: row.id
    };
  }
  get levelOptions() {
    return this.getEnum("AgentLevel");
  }
  get clientTypeOptions() {
    return this.getEnum("ClientType");
  }
  openFreezeOrUnfreezeDialog(index: number, row: any) {
    (this.$refs.freezeOrUnfreezeDialog as MemberFreezeOrUnfreezeDialog).show(
      row.id
    );
  }
  async sortChange(e: any) {
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.getData();
  }
}
